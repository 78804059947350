<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="allowedViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="allowedViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-tree-selector
        [label]="moduleFieldString + '.acknowledgers.label' | translate: 'Acknowledgers'"
        [placeholder]="moduleFieldString + '.acknowledgers.placeholder' | translate: 'Search...'"
        [principleModeSelection]="'ALL'"
        [control]="formGroup?.controls?.acknowledgers"
        [selectedEntities]="data?.acknowledgers"
        [useResponsibilityMode]="true"
        [multi]="true"
        [categoryList]="['RESPONSIBILITIES', 'GROUPS', 'USERS']"
        [viewMode]="allowedViewMode"
      >
      </app-user-and-group-tree-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.approver.label' | translate: 'Approver'"
        [placeholder]="moduleFieldString + '.approver.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [principleModeSelection]="'ALL'"
        [control]="formGroup?.controls?.approver"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="allowedViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.acknowledgmentType.label' | translate: 'Acknowledgment Type'"
        [placeholder]="moduleFieldString + '.acknowledgmentType.placeholder' | translate: 'Select Item'"
        [translationKeyPrefix]="moduleFieldString + '.acknowledgmentType.enums'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="acknowledgmentTypeOptions"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.acknowledgmentType"
        [viewMode]="allowedViewMode"
        [badgeView]="true"
        [showClear]="false"
        (onChanges)="onAkgTypeChanges($event)"
      ></app-drop-down-input>
    </div>
    @if (targetCodeType) {
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="targetCodeLabel"
          [placeholder]="moduleFieldString + '.targetCode.placeholder' | translate: 'Search...'"
          [control]="formGroup?.controls?.targetCode"
          [viewMode]="allowedViewMode"
          [multi]="false"
          [optionLabel]="'label'"
          [optionValue]="'code'"
          [targetTypes]="[targetCodeType]"
        >
        </app-target-code-selector>
      </div>
    }
    <!-- <div class="col-12 md:col-6">
            <ng-container *ngIf="
                    data?.status && data?.status != 'DRAFT'
                        ? false
                        : fieldViewMode != 'view'
                ">
      <app-basic-input label="Document Url"
        [placeholder]="'Enter Document Url'"
        [control]="formGroup?.controls?.documentUrl"
                    [viewMode]="
                        data?.status && data?.status != 'DRAFT'
                            ? 'view'
                            : fieldViewMode
                    "></app-basic-input>
    </ng-container>
            <ng-container *ngIf="
                    data?.status && data?.status != 'DRAFT'
                        ? true
                        : fieldViewMode == 'view'
                ">
      <ng-container *ngIf="formGroup?.controls?.documentUrl?.value">
        <p class="font-bold">Acknowledgment Document</p>
        <app-hyper-text-view [data]="formGroup?.controls?.documentUrl?.value"></app-hyper-text-view>
      </ng-container>
    </ng-container>
  </div>
        <div *ngIf="
                data?.status && data?.status != 'DRAFT'
                    ? false
                    : fieldViewMode != 'view'
            "
    class="col-12 md:col-6 flex gap-2">
    <p-fileUpload mode="basic"
      name="documentUrl[]"
      [styleClass]="'px-3'"
      [chooseLabel]="'Browse'"
      (uploadHandler)="onBasicUpload($event)"
      [customUpload]="true"
      (onSelect)="onSelectFile()"
    #fileBrowser></p-fileUpload>
    <app-button *ngIf="showCancelFile"
      [action]="cancelFileSelectionAction"
    [commandData]="fileBrowser"></app-button>
  </div> -->
    @if (!targetCodeType) {
      <div class="col-12">
        <app-attachment-input
          [label]="moduleFieldString + '.documentUrl.label' | translate: 'Document'"
          [control]="formGroup?.controls?.documentUrl"
          [viewMode]="data?.status && data?.status != 'DRAFT' ? 'view' : fieldViewMode"
          [dragAndDropMode]="true"
          [uploadLocation]="'CODE'"
          [useGlobalPasteEventListener]="false"
          [staticUploadWidth]="false"
        ></app-attachment-input>
      </div>
      <div class="col-12 md:col-6">
        <app-switch-input
          [label]="moduleFieldString + '.requireSignedDocument.label' | translate: 'Require Signed Document'"
          [name]="moduleFieldString + '.requireSignedDocument.placeholder' | translate: 'Require Signed Document'"
          [viewMode]="data?.status && data?.status != 'DRAFT' ? 'view' : fieldViewMode"
          [control]="formGroup?.controls?.requireSignedDocument"
        ></app-switch-input>
      </div>
    }
    <div class="col-12">
      <app-text-editor
        [height]="'115px'"
        [name]="moduleFieldString + '.description.label' | translate: 'description'"
        [label]="moduleFieldString + '.description.placeholder' | translate: 'Description'"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="data?.status && data?.status != 'DRAFT' ? 'view' : fieldViewMode"
      ></app-text-editor>
    </div>
  </div>
  <ng-content></ng-content>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && (!data?.status || data?.status == 'DRAFT')) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton && (!data?.status || data?.status == 'DRAFT')) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton && (!data?.status || data?.status == 'DRAFT')) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
