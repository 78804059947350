import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import {
  AcknowledgmentDto,
  AcknowledgmentRequest,
  AcknowledgmentRequestDto,
  BaseForm,
  getEnumOptions,
  IAction,
  IViewMode,
  ModuleKeywords,
  TargetTypeEnum,
} from '@shared/classes';
import { PathResolverService } from '@shared/services';
import { ViewModeService } from '@shared/services/view-mode.service';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';
import { FileUpload } from 'primeng/fileupload';
import { takeUntil } from 'rxjs';
import { AcknowledgmentTypeMapper } from '../../acknowledgement-request/acknowledgement-request-item-form/acknowledgement-request-item-form.component';
import { AcknowledgementRequestDataService } from '../../services/data/acknowledgement-request-data.service';

@Component({
  selector: 'app-acknowledgement-item-form',
  templateUrl: './acknowledgement-item-form.component.html',
  styleUrls: ['./acknowledgement-item-form.component.scss'],
})
export class AcknowledgementItemFormComponent extends BaseForm<AcknowledgmentDto> implements OnInit {
  @ViewChild('fileBrowser', { static: false }) fileBrowser: FileUpload;
  viewModes = IViewMode;
  acknowledgmentTypeOptions = getEnumOptions(AcknowledgmentDto.AcknowledgmentTypeEnum);

  akgRequestType = TargetTypeEnum.AcknowledgmentRequest;
  showCancelFile = false;
  cancelFileSelectionAction: IAction = {
    id: 2,
    label: 'Cancel',
    buttonType: 'button',
    command: this.clearFile.bind(this),
    icon: 'pi pi-times',
  };
  @Input() hidePrePopulatedValues: boolean = false;
  private _acknowledgementRequestData = null;
  @Input() set acknowledgementRequestData(acknowledgementRequestData: AcknowledgmentRequest) {
    this._acknowledgementRequestData = acknowledgementRequestData;
    if (acknowledgementRequestData) {
      if (acknowledgementRequestData?.requireSignedDocument) {
        this.formGroup.get('signedFileUrl').addValidators([Validators.required]);

        this.formGroup.get('signedFileUrl').updateValueAndValidity();
      }
      this.formGroup.controls.acknowledgmentType.patchValue(acknowledgementRequestData?.acknowledgmentType);
      this.fetchRelatedDocument();
    }
  }
  get acknowledgementRequestData() {
    return this._acknowledgementRequestData;
  }
  documentUrl: string = null;

  constructor(
    public viewModeService: ViewModeService,
    private fileService: FilesDataService,
    private acknowledgementRequestDataService: AcknowledgementRequestDataService,
    private pathResolverService: PathResolverService
  ) {
    super(viewModeService, ModuleKeywords.Acknowledgment);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? {
          ...this.getDataKeyValueFormat(),
          linkedDocuments: this.formGroup.controls.signedFileUrl.value
            ? [this.formGroup.controls.signedFileUrl.value]
            : [],
        }
      : this.getChangedFormValues().updateItems;
  }

  formatGetData() {
    let ret = { ...this.formGroup.getRawValue() };
    ret.acknowledgmentRequest = ret?.acknowledgmentRequest?.code || this.acknowledgementRequestData?.code;
    return ret;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;

    // if (data) {
    //     this.acknowledgementRequestDataService
    //         .getByIdOrCode(data?.acknowledgmentRequest)
    //         .subscribe((res) => {
    //             if (res?.data?.requireSignedDocument) {
    //                 this.formGroup
    //                     .get("signedFileUrl")
    //                     .addValidators([Validators.required]);

    //                 this.formGroup
    //                     .get("signedFileUrl")
    //                     .updateValueAndValidity();
    //             }
    //         });
    // }

    this.checkIfRequestInIclosed(data);
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      approver: new FormControl(null, Validators.required),
      acknowledgmentRequest: new FormControl(null, Validators.required),
      signedFileUrl: new FormControl(null),
      notes: new FormControl(null),
      confirmed: new FormControl(false, Validators.requiredTrue),
      acknowledgmentType: new FormControl(null),
    });

    this.formGroup.controls.acknowledgmentRequest.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res?.documentUrl) {
        this.documentUrl = res?.documentUrl;
      } else {
        this.documentUrl = null;
      }
    });
  }
  onBasicUpload(event) {
    for (let file of event.files) {
      this.fileService
        .uploadFile(file, {
          description: file.name,
          versionName: file.name,
        })
        .subscribe((res) => {
          this.formGroup.controls.signedFileUrl.patchValue(
            `${environment.websiteUrl}/file-manager/documents/download/${res.fileId}`
          );

          this.clearFile(this.fileBrowser);
        });
    }
  }
  onSelectFile() {
    this.showCancelFile = true;
  }
  clearFile(fileBrowser) {
    fileBrowser?.clear();
    this.showCancelFile = false;
  }
  onAkgRequestChange(data) {}

  checkIfRequestInIclosed(ack) {
    if (!!!ack?.acknowledgmentRequest) return;

    let acknowledgmentStatus = this?.data?.acknowledgmentStatus;

    if (!acknowledgmentStatus) return;

    let editStatuses = [
      AcknowledgmentDto.AcknowledgmentStatusEnum.Draft,
      AcknowledgmentDto.AcknowledgmentStatusEnum.Rejected,
    ];

    if (editStatuses.includes(acknowledgmentStatus)) {
      this.acknowledgementRequestDataService
        .getByIdOrCode(ack?.acknowledgmentRequest)
        .pipe(takeUntil(this.destroy$))
        .subscribe((req) => {
          this.viewModeOnly =
            this.viewModeService.viewMode == 'create'
              ? false
              : req?.data?.status == AcknowledgmentRequestDto.StatusEnum.Closed;
        });
    } else {
      this.viewModeOnly = this.viewModeService.viewMode == 'create' ? false : true;
    }
  }
  relatedTargetData: any;
  acknowledgmentTypes = AcknowledgmentDto.AcknowledgmentTypeEnum;
  fetchRelatedDocument() {
    if (this.acknowledgementRequestData) {
      if (AcknowledgmentTypeMapper[this.acknowledgementRequestData?.acknowledgmentType]) {
        this.pathResolverService
          ?.getDataServiceByCode(this.acknowledgementRequestData?.targetCode)
          ?.getByIdOrCode(this.acknowledgementRequestData?.targetCode)
          ?.subscribe((res) => {
            this.relatedTargetData = res.data;
          });
      } else {
      }
    }
  }
}
